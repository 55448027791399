import React from 'react'
import { findIndex } from 'lodash'
import slugify from 'slugify'

import {
  Section,
  Chunk,
  Wrapper,
  Person,
  PersonLink,
  Name,
  Role,
} from './styles'

const orderPeople = people => {
  // filter Jen and Trevor out
  const filtered = people.filter(
    i =>
      i.node.id !== '6d684fee-4bd6-567b-be45-606314a67fd9' &&
      i.node.id !== 'f34ba8ef-618e-55e7-b6f6-8c8e9eeb7dfa'
  )

  const claireIndex = findIndex(filtered, {
    node: { id: '5893126e-5ed2-5a78-9f3c-a96894eca358' },
  })
  filtered.splice(0, 0, filtered.splice(claireIndex, 1)[0])

  const erinIndex = findIndex(filtered, {
    node: { id: '9cd472ec-2b4f-551d-b541-8cf170b06f6f' },
  })
  filtered.splice(0, 0, filtered.splice(erinIndex, 1)[0])

  const saraIndex = findIndex(filtered, {
    node: { id: 'fb98596a-28ca-54f5-b659-61116928cb75' },
  })
  filtered.splice(0, 0, filtered.splice(saraIndex, 1)[0])

  const michelineIndex = findIndex(filtered, {
    node: { id: '4da60fc8-101e-5aec-9e52-529978a1e32f' },
  })
  filtered.splice(0, 0, filtered.splice(michelineIndex, 1)[0])

  const dvIndex = findIndex(filtered, {
    node: { id: '2948401d-8d62-502f-841d-97953cffd412' },
  })
  filtered.splice(filtered.length, 0, filtered.splice(dvIndex, 1)[0])

  return filtered
}

const Content = ({ people, press }) => (
  <div>
    <Section bg="white" color="black">
      <Wrapper maxWidth="80rem">
        <Chunk>
          <h3>Our Mission</h3>
          <div>
            <p>
              The New American Story Project (NASP) is a living archive of
              voices exploring the forces of migration and the lives of new
              Americans. NASP's current project, <i>Welcome Children</i>,
              focuses on Central American unaccompanied minors who fled to the
              U.S. in search of refuge. The project's multimedia constellation
              of stories connects firsthand immigrant testimonies to the
              insights of lawyers, activists, historians, artists, and others to
              illuminate the many aspects of this pressing human issue.
            </p>
          </div>
        </Chunk>
        <Chunk>
          <h3>NASP Team</h3>
          <div>
            {orderPeople(people).map(
              person =>
                person.node.bio ||
                person.node.image ||
                person.node.linkWebsite ||
                person.node.linkTwitter ||
                person.node.linkInstagram ? (
                  <PersonLink
                    to={`collaborators/${slugify(person.node.name, {
                      lower: true,
                    })}`}
                    key={person.node.id}
                  >
                    <Name>{person.node.name}</Name>
                    <Role>{person.node.title}</Role>
                  </PersonLink>
                ) : (
                  <Person key={person.node.id}>
                    <Name>{person.node.name}</Name>
                    <Role>{person.node.title}</Role>
                  </Person>
                )
            )}
          </div>
        </Chunk>
        <Chunk>
          <h3>NASP Press</h3>
          <div>
            {press.map(item => (
              <Person key={item.node.id}>
                <a
                  href={item.node.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Name press>{item.node.title}</Name>
                  <Role>{item.node.organization}</Role>
                </a>
              </Person>
            ))}
          </div>
        </Chunk>
      </Wrapper>
    </Section>
  </div>
)

export default Content
