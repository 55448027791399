import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { take } from 'lodash'

import Head from '../components/Pages/About/Head'
import Content from '../components/Pages/About/Content'
import ChangeTitle from 'containers/change-title'
import config from '../../data/SiteConfig'

const AboutPage = ({ data }) => {
  // changeTitle('About')

  function orderPress(pressList) {
    pressList.sort((a, b) => {
      const dateA = new Date(a.node.published)
      const dateB = new Date(b.node.published)
      return dateB - dateA
    })
    return take(pressList, 10)
  }

  return (
    <div className="about-container">
      <Helmet title={`About | ${config.siteTitle}`} />
      <Head />
      <ChangeTitle title="About" />
      <Content
        people={data.allContentfulCollaborator.edges}
        press={orderPress(data.allContentfulPress.edges)}
      />
    </div>
  )
}

export default AboutPage

/* eslint no-undef: "off" */
export const query = graphql`
  query AboutQuery {
    allContentfulCollaborator(filter: { group: { eq: "Team" } }) {
      edges {
        node {
          id
          title
          name
          field
          bio {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          linkWebsite
          linkTwitter
          linkInstagram
        }
      }
    }
    allContentfulPress {
      edges {
        node {
          id
          title
          organization
          link
          published
        }
      }
    }
  }
`
