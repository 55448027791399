import React from 'react'

import { Section, Text } from './styles'

const Head = () => (
  <div>
    <Section bg="black" color="white" pt="14rem" pb="12rem">
      <Text large>
        The New American Story Project is a space for new Americans to tell
        their stories.
      </Text>
    </Section>
  </div>
)

export default Head
